import $ from "jquery";
window.$ = $;
import "./styles/main.scss";
import "slick-carousel";
import "daterangepicker";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./daterange";
import "./postFilter";


$(() => {
  //campaign slider
  $(".col-slider .slides").slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    draggable: true, 
    mobileFirst: false,
    touchThreshold: 100,
    nextArrow: $("#pageRightIssue"),
    prevArrow: $("#pageLeftIssue"),
  });

  //quotes slider
  $(".supporter-slides").slick({
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: $(".sup-right"),
    prevArrow: $(".sup-left"),
    appendDots: $(".dots-container"),
    responsive: [
      {
        breakpoint: 650,
        settings: {
          adaptiveHeight: true
        }
      }
    ]
  });

  $(".tweets-slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    nextArrow: $("#pageRightTweets"),
    prevArrow: $("#pageLeftTweets"),
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".js-toggleMobileMenu").on("click", function () {
    $("body").toggleClass("mobile-menu-is-active");
  });
  // pagination

});
