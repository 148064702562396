import "daterangepicker";
import moment from "moment";

$(function () {
  $('input[name="daterange"]').off();

  var start = moment().subtract(29, "days");
  var end = moment();

  function cb(start, end) {
    $("#reportrange span").html(
      start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
    );
  }

  $('input[name="daterange"]').daterangepicker({
    showCustomRangeLabel: true,
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    locale: {
      cancelLabel: "Clear",
    },
  });

  $('input[name="daterange"]').on("apply.daterangepicker", function () {
    let filterDates = $(this).val().split("-");
    let startDate = filterDates[0].trim();

    let endDate;
    if (filterDates.length > 1) {
      endDate = filterDates[1].trim();
    }
    filterPostsbyDates(startDate, endDate);

    $(".clear-date").css("display", "block");
  });

  $(".clear-date").on("click", function () {
    $('input[name="daterange"]').data('daterangepicker').setStartDate({});
    $('input[name="daterange"]').data('daterangepicker').setEndDate({});
  });

  cb(start, end);

  function filterPostsbyDates(startDate, endDate) {
    console.log("filtering by: ", startDate, endDate);

    $(".post-items .no-result-text").remove();

    //$(".post-items a").css("display", "none");
    let hasDateFilterPosts = false;

    $(".post-items a:visible").each(function () {
      let post_date = new Date($(this).data("post-date")).getTime();
      let start_date = startDate;
      let end_date = endDate;

      start_date = new Date(start_date).getTime();
      end_date = new Date(end_date).getTime();

      if (post_date >= start_date && post_date <= end_date) {
        $(this).css("display", "flex");
        hasDateFilterPosts = true;
      }
    });

    if (!hasDateFilterPosts) {
      $(".post-items").append(
        '<p class="no-result-text">There are no results matching your criteria. Please update your filter selection.</p>'
      );
    } else {
      $(".post-items .no-result-text").remove();
    }
  }
});
