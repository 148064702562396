//post filter control
let sortActive = false;
let searchActive = false;
let dateActive = false;
let stateFilterActive = false;

let searchTerm;
let posts = $(".post-items a");

$(".post-filters .filter-toggle-order").on("click", function () {
  $(this).find(".toggle-button span").toggleClass("fa-sort-amount-down");
  $(this).find(".toggle-button span").toggleClass("fa-sort-amount-up");
  sortByOrder();
  setPostDisplay();
});

$(".post-filters  .input-wrapper input").on("keyup", function () {
  searchTerm = $(this).val();
  filterBySearchTerm(searchTerm);
  setPostDisplay();
});

$(".post-filters  .select-wrapper select").on("change", function () {
  stateFilterActive = true;
  setPostDisplay();
});

setPostDisplay();

/**
 * Helper Functions for Filter
 */

function filterBySearchTerm(searchTerm) {
  if (searchTerm.length > 1) {
    let filteredPosts = $(".post-items a[exclude!=true]").filter(function () {
      return $(this).attr("title").toLowerCase().indexOf(searchTerm) >= 0;
    });

    console.log(filteredPosts);

    posts = filteredPosts;

    if ($(filteredPosts).length >= 1) {
      $(".post-items a[exclude!=true]").attr("search", false);
      $(filteredPosts).each(function () {
        $(this).attr("search", true);
      });

      $(".post-items .no-result-text").remove();
    } else {
      $(".post-items a[exclude!=true]").attr("search", false);
      $(".post-items .no-result-text").remove();
      $(".post-items").append(
        '<p class="no-result-text">There are no results matching your criteria. Please update your filter selection.</p>'
      );
    }

    searchActive = true;
  } else {
    $(".post-items a[exclude!=true]").attr("search", false);
    $(".post-items .no-result-text").remove();
    searchTerm = "";
    searchActive = false;
  }
}

function filterByState(valueSelected) {
  console.log("filtering by state", valueSelected);

  if (valueSelected != "") {
    if (valueSelected == "national") {
      $(".post-items a[isnationalcampaign!='Yes']:visible").attr(
        "excludeState",
        true
      );

      $(".post-items a[isnationalcampaign!='Yes']:visible").css(
        "display",
        "none"
      );
      //$(".post-items a[isnationalcampaign='Yes']:visible").css("display", "flex");
    } else {
      //$(".post-items a").css("display", "none");
      $(".post-items a[isnationalcampaign!='Yes']:visible").attr(
        "excludeState",
        true
      );

      $(".post-items a[stateid!='" + valueSelected + "']:visible").css(
        "display",
        "none"
      );

      /*$(".post-items a[stateid='" + valueSelected + "']").css(
        "display",
        "flex"
      );*/
    }
    stateFilterActive = true;
  } else {
    stateFilterActive = false;
    $(".post-items a[isnationalcampaign!='Yes']:visible").attr(
      "excludeState",
      false
    );
  }
}

$(".clear-date").on("click", function () {
  setPostDisplay();
  $(this).css("display", "none");
});

function sortByOrder() {
  if (
    $(".post-filters .filter-toggle-order span").hasClass("fa-sort-amount-down")
  ) {
    $(".post-items a")
      .sort((a, b) => {
        return $(a).data("index") - $(b).data("index");
      })
      .appendTo(".post-items");
    sortActive = true;
  } else {
    $(".post-items a")
      .sort((a, b) => {
        return $(b).data("index") - $(a).data("index");
      })
      .appendTo(".post-items");
    sortActive = false;
  }
}

function createPagination() {
  //pagination
  let lastpage = 1;

  // create pagination
  let totalPages = Math.floor(
    $(".component-vue-post-grid .post-items > a:visible").length / 6
  );

  console.log(
    "pages before remainder : " + totalPages,
    $(".component-vue-post-grid .post-items > a:visible").length / 6,
    $(".component-vue-post-grid .post-items > a:visible").length
  );

  // if total post not divisible by 6, add 1 more page to accomodate remaining items
  if ($(".component-vue-post-grid .post-items > a:visible").length % 6 != 0) {
    totalPages++;
  }

  console.log("pages : " + totalPages);

  $(".component-vue-post-grid .nav-links").html("");
  for (let i = 1; i <= totalPages; i++) {
    $(".component-vue-post-grid .nav-links").append(
      "<a class='page-number' href='javascript:void(0);' data-page-number='" +
        i +
        "'>" +
        i +
        "</a>"
    );
  }

  $('.component-vue-post-grid .page-number[data-page-number = "1"]').addClass(
    "active"
  );

  $(".component-vue-post-grid .page-number").on("click", function () {
    let page = $(this).data("page-number");

    if (lastpage == page) return;

    $(".page-number").removeClass("active");

    let showPostsFromIndex = (page - 1) * 6;

    let totalPosts = $(".post-items > a[excludeState!=true]").length;

    let remainingPosts = totalPosts - showPostsFromIndex;

    //console.log(totalPosts, "remainingPostsVisible ", remainingPosts);

    //sort data ascending
    /*$(".post-items a:visible")
      .sort((a, b) => $(a).data("index") - $(b).data("index"))
      .appendTo(".post-items");*/

    $(".post-items > a:visible").css("display", "none");
    if (remainingPosts > 6) {
      $(".post-items > a[excludeState!=true]")
        .slice(0, showPostsFromIndex + 6)
        .css("display", "flex");
    } else {
      $(".post-items > a[excludeState!=true]")
        .slice(showPostsFromIndex, showPostsFromIndex + remainingPosts)
        .css("display", "flex");
    }

    $(this).addClass("active");
    lastpage = page;
  });
}

function setPostDisplay() {
  $(".post-items a").css("display", "flex");

  // check if date filter is active
  if (dateActive) {
    $('input[name="daterange"]').trigger("apply.daterangepicker");
  }

  // check if state is active
  if (stateFilterActive) {
    filterByState($(".post-filters  .select-wrapper select").val());
  }

  // check if search term is active
  if (searchActive) {
    filterBySearchTerm(searchTerm);

    $(".post-items a[search!=true]").each(function () {
      $(this).css("display", "none");
    });
  }

  // check sort
  if (sortActive) {
    sortByOrder();
  }

  // pagination
  createPagination();

  if ($(".post-items a:visible").length > 6) {
    let post_index = 0;
    $(".post-items a:visible").each(function () {
      if (post_index > 5) {
        $(this).css("display", "none");
      }
      post_index++;
    });
  }
}
